<template>
    <div id="belepes" class="login position-relative">
        <div class="container-xl login__content">
            <h2 class="font-header text-dark text-center pb-2 fw-bold">Bejelentkezés</h2>                           
                <div class="row mt-5">                    
                           <!-- ITT KEZDŐDIK A FORM --> 
                            <ValidationObserver 
                                class="col-12 col-xl-8 offset-xl-2"
                                ref="observerLogin" 
                                v-slot="{ handleSubmit }"
                                tag="div"
                            >
                                <div class="">
                                    <form 
                                        class="row "
                                        @submit.prevent="handleSubmit(onSubmit)"
                                    >
                                        <div class="col-12 col-md-6">
                                            <CustomInput
                                                label="E-mail cím"
                                                name="email"
                                                placeholder="pl.: molnarkata@gmail.com"
                                                tooltip="A játékos e-mail címe amit regisztrációnál használt."
                                                v-model="form.email"
                                                rules="required|email"
                                            />
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <CustomInput
                                                type="password"
                                                label="Jelszó"
                                                name="password"
                                                placeholder="Minimum 6 karakter"
                                                tooltip="Regisztráció során megadott jelszó: legalább 6 karakternek kell lennie és speciális karaktereket nem tartalmazhat."
                                                v-model="form.password"
                                                rules="required|min:6"
                                            />
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-center mt-2 mb-0 my-sm-4">
                                                    <input 
                                                        class="btn btn-primary btn-submit" 
                                                        type="submit" 
                                                        value="Bejelentkezés"
                                                    >
                                                </div>
                                            </div>     
                                        </div>                                                                
                                        <div v-if="loginError" class="alert alert-danger">{{loginError}}</div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-center mt-1">                                       
                                                    <router-link :to="{name:'ForgottenPsw'}"                                                    
                                                        class="text-secondary fw-bold text-decoration-underline btn-link text-center mt-0"
                                                    >
                                                        Elfelejtett jelszó?
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ValidationObserver>                     
                  </div>      
            </div>                        
    </div>    
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'
// import errorMessages from "@/setup/errorMsgs.json"

export default {
    components: {
        ValidationObserver,
        CustomInput        
    },
    data () {
        return {
           form: {
                email: '',
                password: ''
            }, 
            loginError: ""
        }
    },
    methods: {
        onSubmit(){        
            let _this = this       

            this.login(this.form.email, this.form.password).then((res)=>{
                if (res.data.status == true){
                    //reset form
                    if (this.$route.name == 'EmailVerification'){
                        this.$router.push({name:'Home', params:{ scrollTo: 'palyazat' }})
                    } else {
                        this.setFormDefaultState()    
                    }
                    
                }
                                
           }).catch((err)=>{            
                //this.setErrorMsgs(err)
                _this.setErrorMsgs(err, 'observerLogin').catch(()=>{
                    this.loginError = "Sikertelen bejelentkezés"

                    setTimeout(() => {
                        this.loginError = ""
                    }, 5000);
                })
           })
        },
        setFormDefaultState(){
            this.form = {
                email: '',
                password: ''
            }
        },
        goToError(err){            
            
            this.setErrorMsgs(err, 'observerLogin').then(()=>{
                let firstError = document.querySelector('#loginModal .input-error')
                if (firstError)
                    firstError.scrollIntoView()                             
            })                                       
        },       
    }
}
</script>