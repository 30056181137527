<template>
    <main>
        <Header />
        <div class="hero-modal-page position-relative">
            <div class="hero-closed-container container-xl">
                <!-- TITLE -->
                <div class="row">                    
                    <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                          <div class="card bg-white form-inner-container form-inner-container-shadow form-inner-container--narrow-content">
                                <div class="card-dialog">
                                    <!-- SUCCESS TEXT -->
                                    <div class="card-content text-center" v-if="isSuccessReg == 'success'">         
                                        <img 
                                            src="@/assets/imgs/ic-reg-success.svg" 
                                            class="img-fluid"
                                        >                                               
                                        <h5 class="card-title text-center w-100 text-red fw-bold my-4 my-md-7">A regisztrációt elfogadtuk</h5>                                         
                                        <div class="card-body text-center text-dark font-secondary">
                                            <p>Megerősítő levelet küldtünk a megadott e-mail címre.</p>
                                            <p>Amennyiben 30 percen belül levelünk nem ér célba (spam mappába és promóciós mappába sem), akkor a <router-link class="text-primary" :to="{name: 'Home', params:{scrollTo: 'kapcsolat'}}"> "<u>Kapcsolat</u>" </router-link> űrlapon keresztül fogadjuk a visszajelzést, hogy segíthessünk.</p>
                                            <p>Az aktiválást követően a <router-link class="text-primary" :to="{name: 'Home', params:{scrollTo: 'belepes'}}"> "<u>Belépés</u>" </router-link> oldalon lehet bejelentkezni.</p>
                                        </div>                
                                        <div class="d-flex justify-content-center">
                                            <router-link :to="{name: 'Home', params:{scrollTo: 'belepes'}}" class="btn btn-primary">Vissza a főoldalra</router-link>
                                        </div>
                                    </div>
                                    <!-- NOT SUCCESS TEXT - email cím már foglalt -->
                                    <div v-else-if="details == 'occupied'" class="card-content text-center">                                      
                                        <img 
                                            src="@/assets/imgs/ic-lock.svg" 
                                            class="img-fluid" 
                                            alt="új jelszó"
                                        >
                                        <h5 class="card-title fs-3 fw-900 text-center w-100 mb-5 text-black text-transform-none mt-4">Sikertelen regisztráció</h5>
                                        <p class="font-secondary">Valami probléma adódott az e-mail cím megerősítése során. Kérjük, vegye fel Velünk a kapcsolatot a <router-link :to="{name: 'Home', params:{scrollTo: 'kapcsolat'}}"> "<u>Kapcsolat</u>" </router-link>  űrlapon keresztül.</p>                  
                                                
                                    </div>
                                    <!-- napi max 3 reg egy ip-ről -->
                                    <div v-else-if="details == 'limit_reached'" class="card-content text-center">                                      
                                        <img 
                                            src="@/assets/imgs/ic-lock.svg" 
                                            class="img-fluid" 
                                            alt="új jelszó"
                                        >
                                        <h5 class="card-title fs-3 fw-900 text-center w-100 mb-5 text-black text-transform-none mt-4">Sikertelen regisztráció</h5>
                                        <p class="font-secondary">Egy IP címről maximum három érvényes regisztráció tehető meg.</p>                  
                                                
                                    </div>
                                    <div v-else class="card-content text-center">                                      
                                        <img 
                                            src="@/assets/imgs/ic-lock.svg" 
                                            class="img-fluid" 
                                            alt="új jelszó"
                                        >
                                        <h5 class="card-title fs-3 fw-900 text-center w-100 mb-5 text-black text-uppercase mt-4">Sikertelen regisztráció</h5>
                                        <p class="font-secondary">Valami probléma adódott, kérjük vegye fel a kapcsolatot velünk.</p>                  
                                                
                                    </div>
                                </div>
                            </div>    
                    </div>
                </div>
            </div>
        </div>
    
      
        <Footer />
    </main>
</template>

<script>
/**
 * isSuccessReg: success or not-success
 * details: occupied or limit_reached
 */
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
    components:{
        Header,
        Footer
    },
   data(){
       return{
           isSuccessReg: null,
           details: null
       }
   },
   mounted(){
       this.isSuccessReg = this.$route.params.result
       this.details = this.$route.params.details
   }
}
</script>